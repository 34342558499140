var render = function () {
  var _vm$validation, _vm$validation$campai, _vm$validation3, _vm$validation3$campa, _vm$validation5, _vm$validation5$campa, _vm$validation7, _vm$validation7$campa;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form"
  }, [_c('sygni-container-title', [_vm._v("Campaign details")]), _c('div', {
    staticClass: "form__row form__row--target"
  }, [_c('div', {
    staticClass: "form__col"
  }, [_c('sygni-input', {
    attrs: {
      "displayFormat": "number",
      "validation": (_vm$validation = _vm.validation) === null || _vm$validation === void 0 ? void 0 : (_vm$validation$campai = _vm$validation.campaignData) === null || _vm$validation$campai === void 0 ? void 0 : _vm$validation$campai.investmentValue,
      "min": 0,
      "placeholder": "0,00",
      "label": "TARGET",
      "disabled": !_vm.isEditable
    },
    on: {
      "blur": function blur($event) {
        var _vm$validation2, _vm$validation2$campa, _vm$validation2$campa2;

        return _vm.setCampaignData((_vm$validation2 = _vm.validation) === null || _vm$validation2 === void 0 ? void 0 : (_vm$validation2$campa = _vm$validation2.campaignData) === null || _vm$validation2$campa === void 0 ? void 0 : (_vm$validation2$campa2 = _vm$validation2$campa.investmentValue) === null || _vm$validation2$campa2 === void 0 ? void 0 : _vm$validation2$campa2.$touch);
      }
    },
    model: {
      value: _vm.campaignData.investmentValue,
      callback: function callback($$v) {
        _vm.$set(_vm.campaignData, "investmentValue", $$v);
      },
      expression: "campaignData.investmentValue"
    }
  })], 1), _c('div', {
    staticClass: "form__col"
  }, [_c('sygni-select', {
    attrs: {
      "options": [{
        label: 'PLN',
        value: 'PLN'
      }],
      "value": 'PLN',
      "label": "",
      "disabled": true,
      "placeholder": ""
    }
  })], 1)]), _c('div', {
    staticClass: "form__row"
  }, [_c('div', {
    staticClass: "form__col"
  }, [_c('sygni-date-picker', {
    attrs: {
      "label": "START",
      "disabledDate": _vm.disabledStartDate,
      "validation": (_vm$validation3 = _vm.validation) === null || _vm$validation3 === void 0 ? void 0 : (_vm$validation3$campa = _vm$validation3.campaignData) === null || _vm$validation3$campa === void 0 ? void 0 : _vm$validation3$campa.startDate,
      "disabled": !_vm.isEditable
    },
    on: {
      "input": function input($event) {
        var _vm$validation4, _vm$validation4$campa, _vm$validation4$campa2;

        return _vm.setCampaignData((_vm$validation4 = _vm.validation) === null || _vm$validation4 === void 0 ? void 0 : (_vm$validation4$campa = _vm$validation4.campaignData) === null || _vm$validation4$campa === void 0 ? void 0 : (_vm$validation4$campa2 = _vm$validation4$campa.startDate) === null || _vm$validation4$campa2 === void 0 ? void 0 : _vm$validation4$campa2.$touch);
      }
    },
    model: {
      value: _vm.campaignData.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.campaignData, "startDate", $$v);
      },
      expression: "campaignData.startDate"
    }
  })], 1), _c('div', {
    staticClass: "form__col"
  }, [_c('sygni-date-picker', {
    attrs: {
      "label": "END",
      "validation": (_vm$validation5 = _vm.validation) === null || _vm$validation5 === void 0 ? void 0 : (_vm$validation5$campa = _vm$validation5.campaignData) === null || _vm$validation5$campa === void 0 ? void 0 : _vm$validation5$campa.endDate,
      "disabled-date": _vm.disabledDate,
      "disabled": !_vm.isEditable
    },
    on: {
      "input": function input($event) {
        var _vm$validation6, _vm$validation6$campa, _vm$validation6$campa2;

        return _vm.setCampaignData((_vm$validation6 = _vm.validation) === null || _vm$validation6 === void 0 ? void 0 : (_vm$validation6$campa = _vm$validation6.campaignData) === null || _vm$validation6$campa === void 0 ? void 0 : (_vm$validation6$campa2 = _vm$validation6$campa.endDate) === null || _vm$validation6$campa2 === void 0 ? void 0 : _vm$validation6$campa2.$touch);
      }
    },
    model: {
      value: _vm.campaignData.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.campaignData, "endDate", $$v);
      },
      expression: "campaignData.endDate"
    }
  })], 1)]), _c('div', {
    staticClass: "form__row"
  }, [_c('div', {
    staticClass: "form__col"
  }, [_c('sygni-input', {
    attrs: {
      "label": "MIN PAYMENT VALUE",
      "displayFormat": "number",
      "disabled": !_vm.isEditable
    },
    on: {
      "input": _vm.setCampaignData
    },
    model: {
      value: _vm.campaignData.investmentPaymentValue.min,
      callback: function callback($$v) {
        _vm.$set(_vm.campaignData.investmentPaymentValue, "min", $$v);
      },
      expression: "campaignData.investmentPaymentValue.min"
    }
  })], 1), _c('div', {
    staticClass: "form__col"
  }, [_c('sygni-input', {
    attrs: {
      "label": "MAX PAYMENT VALUE",
      "displayFormat": "number",
      "disabled-date": _vm.disabledDate,
      "disabled": !_vm.isEditable
    },
    on: {
      "input": _vm.setCampaignData
    },
    model: {
      value: _vm.campaignData.investmentPaymentValue.max,
      callback: function callback($$v) {
        _vm.$set(_vm.campaignData.investmentPaymentValue, "max", $$v);
      },
      expression: "campaignData.investmentPaymentValue.max"
    }
  })], 1)]), _c('div', {
    staticClass: "form__row"
  }, [_c('div', {
    staticClass: "form__col"
  }, [_c('sygni-checkbox', {
    staticClass: "secondary",
    attrs: {
      "label": "USE PAYMENT VALUE STEP",
      "disabled": !_vm.isEditable
    },
    model: {
      value: _vm.hasInvestmentValueStep,
      callback: function callback($$v) {
        _vm.hasInvestmentValueStep = $$v;
      },
      expression: "hasInvestmentValueStep"
    }
  })], 1), _vm.hasInvestmentValueStep ? _c('div', {
    staticClass: "form__col"
  }, [_c('sygni-input', {
    attrs: {
      "label": "STEP VALUE",
      "displayFormat": "number",
      "disabled": !_vm.isEditable
    },
    on: {
      "input": _vm.setCampaignData
    },
    model: {
      value: _vm.campaignData.investmentValueStep,
      callback: function callback($$v) {
        _vm.$set(_vm.campaignData, "investmentValueStep", $$v);
      },
      expression: "campaignData.investmentValueStep"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "form__row"
  }, [_c('sygni-select', {
    attrs: {
      "options": _vm.productSelects.productType,
      "value": _vm.campaignData.type,
      "validation": (_vm$validation7 = _vm.validation) === null || _vm$validation7 === void 0 ? void 0 : (_vm$validation7$campa = _vm$validation7.campaignData) === null || _vm$validation7$campa === void 0 ? void 0 : _vm$validation7$campa.type,
      "label": "TYPE",
      "placeholder": "Select type",
      "allowEmpty": false,
      "disabled": !_vm.isEditable
    },
    on: {
      "input": _vm.setCampaignType
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }